import React, { useEffect, useContext, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { BrinkContext } from "../components/context/BrinkContext"
import { useTranslation, Trans } from "react-i18next"
import { containerMaxWidth } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import ProductGrid from "../components/widgets/ProductGrid"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import Loader from "../components/ui/Loader"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto 6rem;
`

const Title = styled.h1`
  text-align: center;
  padding: 0 5rem;
  line-height: 3rem;
`

const TotalProducts = styled.p`
  text-align: center;
`

const NoResults = styled.div`
  text-align: center;

  p {
    padding: 0 3rem;
  }
`

const Query = styled.span`
  text-transform: initial;
  display: block;
  margin-top: 2rem;
  font-size: 2rem;
`

const SearchResultPage = ({ data: { allSanityProduct }, pageContext }) => {
  const { t } = useTranslation("translations")
  const { searchProducts, isLoading } = useContext(BrinkContext)
  const [products, setProducts] = useState([])

  const { search: queryParams } = useLocation()
  const searchQuery = decodeURI(queryString.parse(queryParams)?.q ?? "")

  const { loginEnabled } = pageContext.sanityLoginPage
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  useEffect(() => {
    const loadSearch = async () => {
      const foundProducts = searchQuery ? await searchProducts(searchQuery) : []
      const products = foundProducts
        .map((product) => {
          if (product.type === "product") {
            return allSanityProduct.nodes.find(
              (sanityProduct) => sanityProduct._id === product.id
            )
          } else {
            return allSanityProduct.nodes
              .map((sanityProduct) => {
                if (
                  sanityProduct.variants.find(
                    (sanityVariant) => sanityVariant._id === product.id
                  )
                ) {
                  return sanityProduct
                } else {
                  return null
                }
              })
              .filter((x) => x)
          }
        })
        .flat()
        .reduce(
          (unique, item) =>
            unique.find((p) => p._id === item._id) ? unique : [...unique, item],
          []
        )
        .filter((p) => p.active && p.onlyVip !== true)
      setProducts(products)
    }
    loadSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  return (
    <LayoutComponent
      meta={{ title: t("Search results") }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs>{t("Search")}</Breadcrumbs>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <Container>
          {products.length > 0 ? (
            <>
              <Title>
                {t("Showing results for")} <Query>'{searchQuery}'</Query>
              </Title>
              <TotalProducts>
                {products.length}{" "}
                {products.length === 1 ? t("item") : t("items")}
              </TotalProducts>
              <ProductGrid searchCards columns="4" products={products} />
            </>
          ) : (
            <NoResults>
              <Title>{t("No matching items")}</Title>
              <p>
                <Trans i18nKey="searchQuery">
                  Your search '<strong>{{ searchQuery }}</strong>' did not match
                  any results.
                </Trans>
                <br />
                {t(
                  "Please check the spelling or try again with a less specific term."
                )}
              </p>
            </NoResults>
          )}
        </Container>
      )}
    </LayoutComponent>
  )
}

export default SearchResultPage

export const query = graphql`
  query {
    allSanityProduct {
      nodes {
        _id
        active
        onlyVip
        genderAttributes {
          maleUseAsDefault
          maleImages {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                sizes: "(max-width: 500px) calc(50vw - 60px), (max-width: 1300px) 550px, 20vw"
              )
            }
          }
          femaleUseAsDefault
          femaleImages {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                sizes: "(max-width: 500px) calc(50vw - 60px), (max-width: 1300px) 550px, 20vw"
              )
            }
          }
          unisexUseAsDefault
          unisexImages {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                sizes: "(max-width: 500px) calc(50vw - 60px), (max-width: 1300px) 550px, 20vw"
              )
            }
          }
        }
        displayName {
          en
        }
        slug {
          current
        }
        variants {
          ...ProductVariants
        }
      }
    }
  }
`
